import React, { Component } from 'react';
import '../styles/ComingSoon.css';
//import pdf from '../dlincoln_resume.pdf';

export class ComingSoon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkText: '😄'
    };

    this.handleOnEnter = this.handleOnEnter.bind(this);
    this.handleOnLeave = this.handleOnLeave.bind(this);
  }

  handleOnEnter() {
    this.setState(() => ({ linkText: '🤩' }));
  }

  handleOnLeave() {
    this.setState(() => ({ linkText: '😄' }));
  }

  render() {
    return (
      <div className="coming-soon">
        <a
          href={"https://www.youtube.com/neshyd"}
          onMouseEnter={this.handleOnEnter}
          onMouseLeave={this.handleOnLeave}
        >
          {this.state.linkText}
        </a>
      </div>
    );
  }
}

export default ComingSoon;
